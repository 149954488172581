<template>
  <div class="product-order-thankyou">
    <div class="product-order-inner">
      <div class="product-order-top-nav">
        <div class="inner-wrapper">
          <a href="https://10in30challenge.com/" target="_blank" class="d-inline-block">
            <img class="logo" src="@/assets/images/product/30-days-challenge-black.webp" height="40" alt="logo" />
          </a>
          <span><i>First Live Session: Monday, May 1st, 5pm ET</i></span>
        </div>
      </div>
      <div class="product-order-body container--full-width container-xl pl-0 pr-0">
        <div class="main-section">
          <p class="title">You're In!<br />Congrats on taking the Challenge!</p>
          <p class="p1">Be on the lookout in your email inbox for your welcome and the next steps.<br /> Then be sure to
            block off the dates for the kickoff on May 1st.</p>
          <p class="p2">If you ever have any questions, be sure to email us at <a class="link"
              href="mailto:support@ringbot.io">support@ringbot.io</a> and we'll be sure to help right away.</p>
          <div class="timer-wrapper">
            <countdown :time="time">
              <div class="w-100" slot-scope="props">
                <div class="timer-block"><span class="timer-number">{{ props.days }}</span><span>Days</span></div>
                <div class="timer-block"><span class="timer-number">{{ props.hours }}</span><span>Hours</span></div>
                <div class="timer-block"><span class="timer-number">{{ props.minutes }}</span><span>Minutus</span></div>
                <div class="timer-block"><span class="timer-number">{{ props.seconds }}</span><span>Seconds</span></div>
              </div>
            </countdown>
          </div>
          <a class="calendar-wrapper" target="_blank"
            href="https://calendar.google.com/calendar/event?action=TEMPLATE&amp;tmeid=NWIwY3Vvc3Jkb2hxdmxyYWoxNTgzb3ZvM3ZfMjAyMzA1MDFUMjEwMDAwWiBtYXR0QHJpbmdib3QuaW8&amp;tmsrc=matt%40ringbot.io&amp;scp=ALL">
            <span>Add Event to Your<br />Favorite Calendar!</span>
            <img class="cal-img" src="@/assets/images/product/calendar.webp" width="36" alt="logo" />
          </a>
        </div>
        <div class="human-section mt-5">
          <div class="human-section_desc">
            <p class="section_title">Your Instructors</p>
            <p class="section_name">Matt Wolach</p>
            <p class="section_label">Entrepreneur, Investor, and Coach, Matt loves startup business growth.</p>
            <p class="section_desc">With 3 startup exits to his name. as well as several others for his business coaching
              clients, Matt knows how to scale companies quickly. Many of his results have come from implementing
              processes and tools that accelerate revenue growth as fast as possible.</p>
          </div>
          <div class="human-section_img">
            <img class="profile-img" src="@/assets/images/product/pro1.webp" width="300" alt="profile" />
          </div>
        </div>
        <div class="human-section b1">
          <div class="human-section_desc">
            <p class="section_name">Michael Knorr</p>
            <p class="section_label">Advisor, Mentor and Agency growth expert.</p>
            <p class="section_desc">Co-founder of That! Company, Michael has consulted with 10,000+ business owners and 3,000+ agency owners in his 16-year profession of building his 7-figure white label digital marketing agency. </p>
          </div>
          <div class="human-section_img">
            <img class="profile-img" src="@/assets/images/product/pro2.webp" width="300" alt="profile" />
          </div>
        </div>
        <div class="text-center">
          <a class="fb-wrapper mt-4" target="_blank" href="https://www.facebook.com/groups/10clientsin30dayschallenge">
            <img class="mr-3" src="@/assets/images/product/fb.webp" width="36" alt="logo" />
            <span class="d-block mt-1"><b>Join the 10 in 30 Facebook Group Now</b></span>
          </a>
        </div>
      </div>
      <div class="product-order-footer">
        <div class="product-order-footer-inner inner-wrapper">
          <div class="main-wrapper">
            <div class="left-wrapper">
              <div class="d-block">
                <a href="https://10in30challenge.com/" target="_blank" class="d-inline-block">
                  <img class="logo" src="@/assets/images/product/30-days-challenge-footer.webp" height="60" alt="logo" />
                </a>
              </div>
              <span class="span-reserved">© 2023 10CLIENTS30DAYS. ALL RIGHTS RESERVED.</span>
            </div>
            <div class="right-wrapper">
              <div class="row mt-3">
                <div class="col-md-12">
                  <a href="https://10in30challenge.com/privacy" target="_blank">PRIVACY POLICY</a>
                </div>
              </div>
              <span class="span-policy-desc">The Challenge and Company is NOT an investment advisory service, NOT an
                investment advisor, <br />
                and does NOT serve as a financial advisor or provide personalized financial advice in any way</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import moment from 'moment-timezone'
import postscribe from 'postscribe'

export default {
  components: {
  },

  data() {
    return {
      time: 0,
    }
  },

  beforeDestroy() {
    if ($('#custom-scripts')) {
      $('#custom-scripts').remove()
    }
  },

  mounted() {
    if (!$('#custom-scripts').length) {
      $('body').append(`<div id="custom-scripts"></div>`)
    }
    let script = `<script>!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '602443691819527');
fbq('track', 'PageView');<\/script>`

    if (process.env.NODE_ENV === 'production')
      postscribe('#custom-scripts', script, {
        done: () => {
        },
      })

    this.time = moment.tz("2023-05-01 17:00:00", "America/New_York").diff(moment())
  },

  methods: {
  },
  computed: {
  },
}
</script>
  
<style lang="scss">
.product-order-thankyou {
  padding-top: 30px;

  .product-order-inner {
    padding-top: 50px;
  }

  .product-order-top-nav {
    background-color: #AD2023;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    color: #fff;

    .inner-wrapper {
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-direction: column;
        span {
          font-size: 17px;
        }
      }
    }

    span {
      font-weight: 700;
      font-size: 22px;
    }
  }

  .inner-wrapper {
    margin: 0 auto;
    max-width: 1024px;
    padding: 10px 0px;
    @media (max-width: 768px) {
      padding: 10px 10px;
    }
  }

  .product-order-body {
    color: #fff;
    text-align: center;
    padding: 40px 10px 0;

    .title {
      font-weight: bold;
      font-size: 40px;
      line-height: 46px;
    }

    p.p1 {
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
    }

    p.p2 {
      font-size: 17px;

      .link {
        color: #fff;
      }
    }

    .timer-wrapper {
      padding: 20px 0 40px;

      .timer-block {
        display: inline-block;
        margin-right: 20px;
        text-align: center;
        font-size: 13px;
        span.timer-number {
          display: flex;
          background-color: #fff;
          color: #AD2023;
          border-radius: 50%;
          width: 55px;
          height: 54px;
          font-weight: bold;
          font-size: 27px;
          align-items: center;
          justify-content: center;
          margin-bottom: 5px;
        }
      }
    }

    .calendar-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      background-color: #fff;
      color: #000;
      width: 300px;
      border-radius: 5px;

      span {
        font-size: 12px;
        line-height: 15px;
        font-weight: bold;
      }

      text-decoration: none;
    }

    .fb-wrapper {
      display: flex;
      margin: auto;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      background-color: #fff;
      color: #000;
      width: 470px;
      border-radius: 5px;
      text-decoration: none;

      span {
        font-size: 21px;
      }
      @media (max-width: 768px) {
        width: auto;
        margin: 0 10px;
      }
    }

    .human-section {
      background-color: #1E1E1E;
      display: flex;
      align-items: center;
      padding: 30px 50px;

      &.b1 {
        flex-direction: row-reverse;
        background-color: #323232;
      }

      .human-section_desc {
        flex: 1 1 auto;
        text-align: left;

        p {
          padding: 0;
          margin: 0;
        }

        .section_title {
          font-weight: bold;
          font-size: 26px;
          padding-bottom: 10px;
        }

        .section_name {
          font-size: 22px;
          padding-bottom: 5px;
        }

        .section_label {
          font-size: 18px;
          font-style: italic;
          font-weight: 600;
          color: #4caf50;
          padding-bottom: 5px;
        }

        .section_desc {
          font-size: 13px;
        }
      }

      .human-section_img {
        flex: 0 0 40%;
      }

      @media (max-width: 768px) {
        flex-direction: column-reverse !important;
        padding: 30px 10px;
        .human-section_desc {
          text-align: center;
          padding-top: 20px;
        }
      }
    }
  }

  .product-order-footer {
    width: 100%;
    background-color: #fff;
    margin-top: 20px;

    .product-order-footer-inner {
      font-weight: 600;
      padding: 10px 0;
    }

    .main-wrapper {
      color: #000 !important;
    }

    .span-reserved {
      font-size: 13px;
      padding-top: 10px;
      display: block;
    }

    .span-policy-desc {
      display: block;
      font-size: 11px;
      padding-top: 10px;
    }
  }

  .red {
    color: #AD2023
  }

  .green {
    color: #4caf50;
  }

  .line-through {
    text-decoration: line-through;
  }

  .input,
  .multiselect__tags {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
    font-size: 16px;
    height: 42px;
  }

  .main-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: #F6F5FF;

    .left-wrapper {
      flex: 0 0 40%;
    }

    .right-wrapper {
      flex: 1 1 auto;
      padding-left: 50px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      .left-wrapper {
        width: 100%;
        padding: 0 10px;
        text-align: center;
      }
      .right-wrapper {
        width: 100%;
        padding: 0 10px;
        text-align: center;
      }
    }
  }
  @media (max-width: 768px) {
    .main-section {
      padding: 0 10px;
    }
  }
}</style>
  